<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Detail Report ke <strong>{{ data.id }}</strong> dari <strong>{{ data.nama}}</strong> dikirim pada tanggal <strong>{{ data.created_at }} </strong></div>
            <div class="card-body">
              <form>
                <div class="form-group">
                  <label><strong>Nama</strong></label>
                  <br/>
                  <p>{{ data.nama}}</p>
                  <hr/>
                  <label><strong>ID APG</strong></label>
                  <br/>
                  <p>{{ data.id_apg }}</p>
                  <hr/>
                  <label><strong>Keluhan</strong></label>
                  <br/>
                  <div style="text-align: justify" v-html="data.keluhan"></div>
                  <hr/>
                  <label><strong>File</strong></label>
                  <br/>
                  <a v-bind:href="data.file">{{ data.file }} </a>
                  
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index";
import { urlAPI } from '../../config/api';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      data: {},
      validation: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    axios
      .get(
        `${urlAPI}/client/report/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },
};
</script>
