<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">EDIT File Football</div>
            <div class="card-body">
              <form @submit.prevent="organizationUpdate">
                <div class="form-group">
                  <label>Keterangan</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.text"
                    placeholder="Masukkan Nama Organization"
                  />
                  <div v-if="validation.text">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.text[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Tipe File</label>
                  <select class="form-control" v-model="data.tipe">
                    <option>pdf</option>
                    <option>foto</option>
                    <option>video</option>
                  </select>
                  <div v-if="validation.tipe">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.tipe[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="file"
                    @change="onFileUpload"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md btn-success mr-2">
                    UPDATE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { urlAPI } from '../../../config/api';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      data: {},
      validation: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    axios
      .get(
        `${urlAPI}/client/football/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    organizationUpdate() {
      const formData = new FormData();
      formData.append("file", this.FILE);
      formData.append("text", this.data.text);
      formData.append("tipe", this.data.tipe);
      axios
        .post(
          `${urlAPI}/client/football/update/${this.$route.params.id}`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "football-dashboard",
          });
          console.log(response);
        })
        .catch((error) => {
          this.validation = error.response.data.data;
        });
    },
  },
};
</script>
