<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Tambah File Tennis</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="form-group">
                  <input type="file" @change="onFileUpload" />
                </div>
                <div class="form-group">
                    <label> Keterangan </label>
                  <input
                    type="text"
                    v-model="text"
                    placeholder="Nama File Untuk Tennis"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                    <label>Tipe File Upload</label>
                  <select class="form-control" v-model="tipe">
                    <option>pdf</option>
                    <option>foto</option>
                    <option>video</option>
                  </select>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary btn-sm">
                    Upload File Tennis
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { urlAPI } from '../../../config/api';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      FILE: null,
      text: "",
      tipe: '',
      token: localStorage.getItem("token"),
    };
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    onSubmit() {
      // upload file
      const formData = new FormData();
      formData.append("file", this.FILE);
      formData.append("text", this.text);
      formData.append("tipe", this.tipe);
      axios
        .post(
          `${urlAPI}/client/tennis/create`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "tennis-dashboard",
          });
          console.log(response.data.data);
        });
    },
  },
};
</script>
