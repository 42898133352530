<template>
  <div class="login">
    <div class="container" style="margin-top: 100px">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="card">
            <div class="card-body">
              LOGIN DASHBOARD APLIKASI APG
              <hr />
              <form @submit.prevent="login">
                <div class="form-group">
                  <label>ID APG</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.id_apg"
                    placeholder="Masukkan ID APG"
                  />
                  <div v-if="validation.id_apg" class="mt-2 alert alert-danger">
                    Masukkan ID APG
                  </div>
                </div>

                <div class="form-group">
                  <label>PASSWORD</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="user.password"
                    placeholder="Masukkan Password"
                  />
                  <div
                    v-if="validation.password"
                    class="mt-2 alert alert-danger"
                  >
                    Masukkan Password
                  </div>
                </div>
                <div v-if="loginFailed" class="mt-2 alert alert-danger">
                  ID APG atau Password Anda salah.
                </div>
                <button type="submit" class="btn btn-primary">LOGIN</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { urlAPI } from '../../config/api';

export default {
  name: 'Login',

  data() {
    return {
      //state loggedIn with localStorage
      loggedIn: localStorage.getItem('loggedIn'),
      //state token
      token: localStorage.getItem('token'),
      //state user
      user: [],
      //state validation
      validation: [],
      //state login failed
      loginFailed: null,
    };
  },
  methods: {
    login() {
      if (this.user.id_apg && this.user.password) {
        axios
          .post(`${urlAPI}/login`, {
            headers: { 'Content-Type': 'multipart/form-data' },
            id_apg: this.user.id_apg,
            password: this.user.password,
          })
          .then((res) => {
            //debug user login
            console.log(res);

            if (res.data.message) {
              //set localStorage
              localStorage.setItem('loggedIn', 'true');

              //set localStorage Token
              localStorage.setItem('token', res.data.data.api_token);

              //change state
              this.loggedIn = true;

              //redirect dashboard
              return this.$router.push({ name: 'dashboard' });
            } else {
              //set state login failed
              this.loginFailed = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      this.validation = [];

      if (!this.user.id_apg) {
        this.validation.id_apg = true;
      }

      if (!this.user.password) {
        this.validation.password = true;
      }
    },
  },

  //check user already logged in
  mounted() {
    if (this.loggedIn) {
      return this.$router.push({ name: 'dashboard' });
    }
  },
};
</script>
