<template>
  <div class="dashboard" style="margin-top: 40px; margin-bottom: 40px;">
    <div class="container">
      <div class="row">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              DASHBOARD
              <hr />
              Selamat Datang <strong>{{ user.name }}</strong> di dashboard
              aplikasi mobile Asosiasi Pilot Garuda.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index"
import { urlAPI } from "../../config/api"

export default {
  name: "Dashboard",
  components: {
    Sidebar
  },

  data() {
    return {
      //state loggedIn with localStorage
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user logged In
      user: [],
    };
  },

  created() {
    console.log(this.token);
    axios
      .get(`${urlAPI}/client/user`, {
        headers: { Authorization: "Bearer " + this.token },
      })
      .then((response) => {
        this.user = response.data.data; // assign response to state user
      });
  },

  methods: {
    logout() {
      const formData = new FormData();
      formData.append("api_token", this.token);
      axios
        .post(`${urlAPI}/logout`, formData)
        .then(() => {
          //remove localStorage
          localStorage.removeItem("loggedIn");
          //redirect
          return this.$router.push({ name: "home" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  //check user logged in or not
  mounted() {
    if (!this.loggedIn) {
      return this.$router.push({ name: "home" });
    }
  },
};
</script>