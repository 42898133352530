<template>
  <div class="dashboard" style="margin-top: 40px; margin-bottom: 40px">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-12 col-sm-12">
          <div v-for="data in info" :key="data.id" class="card">
            <div class="card-header">
              <div class="text-center">
                <strong>{{ data.nama_sport }}</strong>
              </div>
            </div>
            <div  class="card-body">
              <div class="text-center" style="margin-bottom: 10px">
                <img
                  v-bind:src="data.file"
                  class="img-fluid"
                  style="height: 50px, width: 50px"
                />
              </div>
              <div v-html="data.desc_singkat"></div>
              <div style="text-align: justify" v-html="data.desc_full"></div>
              <div
                class="text-center"
                style="
                  margin-top: -10px;
                  margin-bottom: 10px;
                  text-decoration: underline;
                  font-size: 20px;
                "
              >
                <strong>Member</strong>
              </div>
              <div style="text-align: justify" v-html="data.member"></div>
              <div
                class="text-center"
                style="
                  margin-top: 10px;
                  margin-bottom: 10px;
                  text-decoration: underline;
                  font-size: 20px;
                "
              >
                <strong>Event</strong>
              </div>
              <div style="text-align: justify" v-html="data.event"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from '../../config/api';

export default {
  name: "Dashboard",

  data() {
    return {
      info: null,
      loading: true,
      errored: false,
    };
  },
  mounted() {
    axios
      .get(`${urlAPI}/badminton`)
      .then((response) => {
        this.info = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>