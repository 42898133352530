<template>
  <div class="col-xs-12 col-md-12 col-sm-12" style="margin-top: 10px; margin-bottom: 10px;">
    <div class="card">
      <div class="card-header">
        <div class="text-center">Legal Agreement</div>
      </div>
      <div class="card-body">
        <div class="content-area">
          <div class="container">
            <p>
              Kebijakan Privasi berikut ini menjelaskan bagaimana kami, Aplikasi Asosiasi Pilot Garuda
              (PT. Trend Media Global dan afiliasi-afiliasi, atau "kami")
              mengumpulkan, menyimpan, menggunakan, mengolah, menguasai,
              mentrasfer, menggungkapkan dan melindungi informasi Pribadi anda.
              Kebijakan Privasi ini berlaku bagi seluruh pengguna
              aplikasi-aplikasi, situs web (www.apg.or.id), layanan, atau
              produk (<b>"Aplikasi”</b>) kami, kecuali diatur pada kebijakan
              privasi yang terpisah.
            </p>

            <p>
              Mohon baca kebijakan Privasi ini dengan seksama untuk memastikan
              bahwa anda memahami bagaimana proses pengolahan data kami. Kecuali
              didefenisikan lain, semua istilah dengan huruf kapital yang
              digunakan dalam Kebijakan Privasi ini memiliki arti yang sama
              dengan yang tercantum dalam Ketentuan Penggunaan.
            </p>

            <p>Kebijakan Privasi ini mencakup hal-hal sebagai berikut:</p>

            <ol>
              <li>Informasi Pribadi yang kami kumpulkan</li>
              <li>Penggunaan Informasi Pribadi yang kami kumpulkan</li>
              <li>Pemberian Informasi Pribadi yang kami kumpulkan</li>
              <li>Penyimpanan Informasi Pribadi</li>
              <li>Akses dan koreksi Informasi Pribadi</li>
              <li>Tempat kami menyimpan Informasi Pribadi anda</li>
              <li>Keamanan Informasi Pribadi anda</li>
              <li>Perubahan atas Kebijakan Privasi ini</li>
              <li>Pengakuan dan persetujuan</li>
              <li>Materi pemasaran dan promosi</li>
              <li>Data anonim</li>
              <li>Platform pihak ketiga</li>
              <li>Cara menghubungi kami</li>
            </ol>

            <ol>
              <li>
                <p class="font-weight-bold font-catamaran">
                  INFORMASI PRIBADI YANG KAMI KUMPULKAN
                </p>

                <p>
                  Kami mengumpulkan informasi yang mengidentifikasikan atau
                  dapat digunakan untuk mengidentifikasi, menghubungi, atau
                  menemukan orang atau perangkat yang terkait dengan informasi
                  tersebut (<b>“Informasi Pribadi”</b>). Informasi Pribadi
                  termasuk, tetapi tidak terbatas pada, nama, alamat, tanggal
                  lahir, pekerjaan, nomor telepon, nomor faks, alamat e-mail,
                  rekening bank dan detail kartu kredit, jenis kelamin,
                  identifikasi (termasuk KTP, SIM, atau Paspor) atau tanda
                  pengenal lainnya yang dikeluarkan oleh pemerintah, foto
                  kewarganegaraan, nomor telepon pengguna dan non-pengguna
                  Aplikasi kami yang terdaftar dalam daftar kontak telefon
                  selular anda, serta informasi biometrik (termasuk namun tidak
                  terbatas pengenalan wajah). Selain itu, untuk informasi
                  lainnya, seperti profil pribadi, dan/atau nomor pengenal unik,
                  yang dikaitkan atau digabungkan dengan informasi Pribadi, maka
                  informasi tersebut juga dianggap sebagai informasi Pribadi.
                  Informasi Pribadi yang kami kumpulkan dapat diberikan oleh
                  anda secara langsung atau oleh pihak ketiga (misalnya: ketika
                  anda mendaftar atau menggunakan Aplikasi, ketika anda
                  menghubungi layanan pelanggan kami, atau sebaliknya ketika
                  anda memberikan Informasi Pribadi kepada kami). Kami dapat
                  mengumpulkan informasi dalam berbagai macam bentuk dan tujuan
                  (termasuk tujuan yang diizinkan berdasarkan peraturan
                  perundang-undangan yang berlaku).
                </p>

                <p class="font-weight-bold font-catamaran">
                  Informasi yang kami kumpulkan dari anda atau perangkat seluler
                  anda secara langsung
                </p>

                <ul>
                  <li>
                    Ketika anda mendaftar dan membuat akun dengan kami
                    menggunakan Aplikasi, anda harus memberikan kepada kami
                    Informasi Pribadi tertentu, termasuk nama, alamat fisik,
                    alamat e-mail, nomor telepon anda, dan/atau kata sandi yang
                    akan anda gunakan untuk mengakses Aplikasi setelah
                    pendaftaran. Jika anda menggunakan Aplikasi sebagai penyedia
                    layanan, anda harus memberikan Informasi Pribadi tambahan
                    kepada kami sebagai bagian dari proses pendaftaran penyedia
                    layanan. Ini akan mencakup rincian mengenai kendaraan anda
                    (izin, persetujuan, dan otorisasi lainnya yang anda perlukan
                    untuk mengoperasikan kendarraan dan untuk menyediakan
                    layanan transportasi), polis asuransi anda, dan rekening
                    bank anda untuk keperluan menerima pembayaran.

                    <ul>
                      <li>
                        Jika anda menggunakan Aplikasi sebagai pengguna, anda
                        harus memberikan informasi pada kami seseuai dengan
                        jenis layanan yang anda cari, dan rincian penjemputan
                        dan/atau pengiriman
                      </li>
                      <li>
                        Jika anda menggunakan Aplikasi sebagai penyedia layanan,
                        agar Aplikasi dapat berfungsi, anda harus memeberikan
                        kepada kami informasi terkait dengan layanan yang anda
                        dapat terima pesanananya pada saat itu, dan perinciannya
                        pada saat ini, dan setelah pesanan untuk layanan yang
                        ditempatkan oleh penggunaan diterima oleh anda sebagai
                        penyedia layanan, anda mungkin akan perlu memberikan
                        kepada kami data lain yang kami perlukan untuk mengelola
                        Aplikasi dan ekosistem terkait, serta untuk memantau
                        keseluruhan penggunaan Aplikasi.
                      </li>
                      <li>
                        Jika anda menggunakan dan/atau ketika pembayaran atau
                        transfer dilakukan melalui fasilitas uang elektronik
                        dan/atau dompet elektronik yang disediakan oleh kami,
                        jika anda adalah pihak pembayar, anda akan menyediakan
                        informasi kepada kami yang berkaitan dengan penggunaan,
                        pembayaran atau transfer, termasuk tetapi tidak terbatas
                        pada rincian transfer dan/atau penerima pembayaran,
                        jumlah pembayaran yang dibayarkan, jenis kartu
                        pembayaran yang digunakan, nama penerbit kartu
                        pembayaran tersebut, nama pemegang rekening dan nomor
                        kartu pembayaran tersebut.
                      </li>
                      <li>
                        Jika anda bermaksud untuk mendaftar sebagai pemegang
                        akun terdaftar atau terverifikasi dari fasilitas uang
                        elektronik yang disediakan oleh kami, anda akan
                        memberikan informasi kepada kami yang diperlukan
                        termasuk tetapi tidak terbatas pada nama lengkap anda,
                        nomor kartu identitas anda, jenis kartu identitas yang
                        anda gunakan untuk registrasi, alamat, jenis kelamin,
                        tempat tanggal lahir, pekerjaan, kebangsaan dan/atau
                        data biometrik.
                      </li>
                    </ul>
                  </li>
                </ul>

                <p class="font-weight-bold font-catamaran">
                  Informasi yang dikumpulkan setiap kali anda menggunakan
                  Aplikasi atau mengunjung situs web kami
                </p>

                <ul>
                  <li>
                    Setiap kali anda menggunakan Aplikasi atau mengunjungi situs
                    web kami, kami dapat mengumpulkan data teknis tertentu
                    sehubungan dengan penggunaan sanda seperti, alamat protokol
                    internet, informasi halaman web yang sebelumnya atau
                    selanjutnya dilihat, durasi setiap kunjungan/sesi, identitas
                    perangkat internet atau alamat kontrol akses media, dan
                    informasi mengenai produsen, mode, dan sistem operasi dari
                    perangkat yang anda gunakan untuk mengakses Aplikasi atau
                    situs web kami.
                  </li>
                  <li>
                    Ketika anda menggunakan Aplikasi atau mengunjungi situs web
                    kami, informasi tertentu juga dapat dikumpulkan secara
                    otomatis dengan menggunakan cookies. Cookies adalah berkas
                    data kecil yang tersimpan pada komputer atau perangkat
                    seluler anda. Kami menggunakan cookies untuk melacak
                    aktivitas pengguna dengan tujuan untuk meningkatkan
                    anatarmuka dan pengalaman pengguna. Sebagaian besar dan
                    perangkat seluler dan peramban internet mendukung penggunaan
                    cookies; namun anda dapat menyesuakan pengaturan pada
                    perangkat seluler atau peramban internet anda untuk menolak
                    beberapa jenis cookes atau cookies spesifik tertentu.
                    Perangkat seluler dan/atau peramban anda juga memungkinkan
                    anda untuk menghapus cookies apa pun yang sebelumnya telah
                    tersimpan. Namun demikian, hal itu dapat mempengaruhi fungsi
                    yang tersedia pada Aplikasi atau situs web kami.
                  </li>
                  <li>
                    Setiap kali anda menggunakan Aplikasi melalui perangkat
                    seluler, kami akan melacak dan mengumpulkan informasi lokasi
                    geografis anda dalam waktu sebenarnya. Dalam beberapa kasus,
                    anda akan diminta atau diharuskan untuk mengaktifkan Global
                    Positioning System (GPS) pada perangkat seluler anda untuk
                    memungkinkan kami agar dapat memberikan anda pengalaman yang
                    lebih baik dalam menggunakan Aplikasi (misalnya, untuk
                    memberikan informasi tentang seberapa dekat penyedia layanan
                    dengan anda). Anda dapat menonaktifkan informasi pelacakan
                    lokasi geografis pada perangkat seluler anda untuk sementara
                    waktu. Namun, hal ini dapat mempengaruhi fungsi-fungsi yang
                    tersedia pada Aplikasi.
                  </li>
                  <li>
                    Jika anda menggunakan dan/atau ketika pembayaran atau
                    transferdi lakukan mellui fasilitas uan elektronik dan/atau
                    dompet elektronik yang disediakan oleh kami, kami dapat
                    mengumpulkan informasi tertentu terkait dengan sumber dana
                    yang anda gunakan untuk melakukan isi ulang (termasuk
                    rincian rekening bank), rincian rekening penerima penarikan,
                    riwayat transaksi (termasuk detil penerima), rincian
                    tagihan, faktur, dan nomor telepon.
                  </li>
                </ul>

                <p class="font-weight-bold font-catamaran">
                  Informasi yang dikumpulkan dari pihak ketiga
                </p>

                <p>
                  Kami juga dapat mengumpulkan informasi Pribadi anda dari pihak
                  ketiga (termasuk agen, vendor, pemasok, kontraktor, mitra, dan
                  pihak lainnya yang memberikan layanan kepada kami, melakukan
                  tugas atas nama kami, atau dengan siapa kami melakukan kerja
                  sama). Dalam kasus tersebut, kami hanya akan mengumpulkan
                  informasi Pribadi anda untuk atau sehubungan dengan tujuan
                  yang melibatkan pihak ketiga tersebut atau tujuan kerja sama
                  kami dengan pihak ketiga tersebut (tergantung pada
                  situasinya).
                </p>

                <p class="font-weight-bold font-catamaran">
                  Informasi tentang pihak ketiga yang anda berikan kepada kami
                </p>

                <ol type="a">
                  <li>
                    Anda dapat memberikan kepada kami Informasi Pribadi yang
                    berkaitan dengan individu pihak ketiga lainnya (termasuk
                    Informasi Pribadi yang berkaitan dengan pasangan anda,
                    anggota keluarga, teman, atau individu lain). Anda tentu
                    saja akan memerlukan persetujuan dari mereka terlebih dahulu
                    untuk melakukannya – lihat “Pengakuan dan Persetujuan”, di
                    bawah, untuk informasi lebih lanjut
                  </li>
                  <li>
                    Kami tidak mewajibkan atau mendukung, ataupun melarang,
                    pemasangan atau penggunaan alat perekam dalam kendaraan di
                    kendaraan apa pun. Jika perangkat tersebut dipasang dan
                    digunakan di kendaraan, kami tidak mengumpulkan Informasi
                    Pribadi apapun dari rekaman dalam kendaraan oleh perangkat
                    tersebut, dan pengumpulan Informasi Pribadi tersebut oleh
                    penyedia layanan dan setiap penggunaan atau pengungkapan
                    oleh penyedia layanan dari Informasi Pribadi yang
                    dikumpulkan. Jika anda adalah penyedia layanan dan memilih
                    untuk mengunduh dan menggunakan perangkat rekaman di dalam
                    kendaraan tersebut di kendaraan anda, anda bertanggung jawab
                    sepenuhnya untuk memberitahukan pengguna. Jika anda adalah
                    seorang pengguna dan anda keberatan atas penggunaan alat
                    perekam dalam kendaraan penyedia layanan, anda harus
                    menyampaikan hal tersebut kepada penyedia layanan secara
                    langsung dan hal tersebut menjadi tanggung jawab pribadi
                    anda.
                  </li>
                  <li>
                    Jika anda berkeinginan untuk menggunakan fiter pesan di
                    Aplikasi kami, anda akan memberikan nomor telefon pengguna
                    kami yang tersimpan di kontak telefon selular anda secara
                    rutin untuk membantu anda dalam menggunakan fitur pesan kami
                    serta tujuan internal lainnya.
                  </li>
                </ol>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  PENGGUNAAN INFORMASI PRIBADI YANG KAMI KUMPULKAN
                </p>
                <p>
                  Kami dapat menggunakan Informasi Pribadi yang dikumpulkan
                  untuk tujan sebagai berikut maupun untuk tujuan lain yang
                  diizinikan oleh peraturan perundang-undangan yang berlaku
                  (<b>"Tujuan"</b>)
                </p>

                <ol type="a">
                  <li>
                    Jika anda adalah pengguna, kami dapat menggunakan Informasi
                    Pribadi anda:
                    <ul>
                      <li>
                        Untuk mengidentifikasi dan mendaftarkan anda sebagai
                        pengguna dan untuk mengadministrasikan, memverifikasi,
                        menonaktifkan, atau mengelola akun anda;
                      </li>
                      <li>
                        Untuk memfasilitasi atau memungkinkan verifikasi apapun
                        yang menurut pertimbangan kami diperlukan sebelum
                        penyedia layanan memeberikan layanan kepada anda atau
                        sebelum kami mendaftarkan anda sebagai pengguna,
                        termasuk proses Mengenal Pelanggan (Know Your Customer);
                      </li>
                      <li>
                        Untuk memungkinkan penyedia layanan untuk menyediakan
                        layanan yang anda minta;
                      </li>
                      <li>
                        Untuk mengolah dan memfasilitasi pesan dan transaksi
                        pembayaran yang anda lakukan;
                      </li>
                      <li>
                        Untuk berkomunikasi dengan anda dan mengirimkan anda
                        informasi sehubungan dengan penggunaan Aplikasi;
                      </li>
                      <li>
                        Untuk memberitahu anda mengenai segala pembaruan pada
                        Aplikasi atau perubahan pada layanan yang disediakan;
                      </li>
                      <li>
                        Untuk mengolah dan menanggapi pertanyaan dan saran yang
                        diterima dari anda;
                      </li>
                      <li>
                        Untuk memelihara, mengembangkan, menguji, meningkatkan,
                        dan mempersonalisasikan Aplikasi untuk memenuhi
                        kebutuhan dan preferensi anda sebagai pengguna;
                      </li>
                      <li>
                        Untuk memantau dan menganalisis aktivitas, perilaku, dan
                        data demografis pengguna termasuk kebiasaan dan
                        penggunaan berbagai layanan yang tersedia di Aplikasi;
                      </li>
                      <li>
                        Untuk menawarkan atau menyediakan layanan dari afiliasi
                        atau mitra kami; dan
                      </li>
                      <li>
                        Untuk mengirimkan anda komunikasi pemasaran, iklan,
                        voucher, survei, dan informasi secara langsung atau
                        terfokus, dan informasi tentang penawaran atau promosi
                        khusus.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Jika anda adalah penyedia layanan, kami dapat menggunakan
                    Informasi pribadi anda:

                    <ul>
                      <li>
                        Untuk mengidentifikasi anda dan mendaftarkan anda
                        sebagai peneyedia layanan dan untuk mengadministrasi,
                        mengelola atau memverifikasi Akun anda;
                      </li>
                      <li>
                        Untuk memfasilitasi atau memungkinkan verifikasi apapun
                        yang menurut pertimbangan kami mungkin perlu dilakukan
                        sebelum kami dapat mendaftarkan anda sebagai penyedia
                        layanan;
                      </li>
                      <li>
                        Untuk berkomunikasi dengan anda dan mengirimkan anda
                        informasi terkait dengan penyediaan layanan anda,
                        termasuk untuk menyampaikan pesanan pengguna kepada anda
                        dan untuk memfasilitasi penerimaan atas pesanan
                        tersebut;
                      </li>
                      <li>
                        Untuk memberikan anda pemberitahuan dan pembaruan pada
                        Aplikasi atau perubahaan cara penyediaan layanan;
                      </li>
                      <li>
                        Untuk memberi anda laporan terkait dengan layanan yang
                        terlah anda sediakan;
                      </li>
                      <li>
                        Untuk memproses dan menanggapi saran dari pengguna
                        terhadap layanan yang telah anda berikan;
                      </li>
                      <li>
                        Untuk memelihara, mengembangkan, menguji, meningkatkan,
                        dan mempersonalisasikan Aplikasi untuk memenuhi
                        kebutuhan dan prefensi anda sebagai penyedia layanan;
                      </li>
                      <li>
                        Untuk memantau dan menganalisis aktivitas, perilaku, dan
                        data demografis pengguna termasuk kebiasaan dan daya
                        tanggap penyedia layanan untuk berbagai layanan yang
                        tersedia pada Aplikasi;
                      </li>
                      <li>Untuk memproses dan mengelola reward point anda;</li>
                      <li>
                        Untuk menawarkan atau menyediakan layanan dari afiliasi
                        atau mitra kami;
                      </li>
                      <li>
                        Untuk mengirimkan anda komunikasi pemasaran, iklan,
                        promo, survei, dan penawaran khusus atau promosi secara
                        langsung atau tertuju
                      </li>
                    </ul>
                  </li>
                  <li>
                    Baik anda merupakan pengguna atayupun penyedia layanan atau
                    pihak yang memberikan Informasi Pribadi kepada kami, kami
                    juga dapat menggunakan Informasi Pribadi anda secara lebih
                    umum untuk tujuan-tujuan sebagai berikut (walaupun dalam
                    kasus tertentu kami akan bertindak secara wajar dan tidak
                    menggunakan Informasi Pribadi anda lebih dari apa yang
                    diperlukan untuk tujuan tersebut):

                    <ul>
                      <li>Untuk melakukan proses dan fungsi bisnis terkait;</li>
                      <li>
                        Untuk memantau penggunaan Aplikasi dan mengelola,
                        mendukung serta meningkatkan efesiensi kinerja,
                        perkembangan, pengalaman pengguna dan fungsi-fungsi
                        Aplikasi;
                      </li>
                      <li>
                        Untuk memberikan bantuan sehubungan dengan dan untuk
                        menyelesaikan kesulitan teknis atau masalah operasional
                        dengan Aplikasi atau layanan;
                      </li>
                      <li>
                        Untuk menghasilkan informasi statistic dan data analitik
                        untuk tujuan pengujian, penelitian analisis,
                        pengembangan produk, kemitraan komersial, dan kerja
                        sama;
                      </li>
                      <li>
                        Untuk mencegah, mendeteksi dan menyelidiki segala
                        kegiatan yang dilarang, illegal, tidak sah, atau curang;
                      </li>
                      <li>
                        Untuk memfasilitasi transaksi asset bisnis (yang dapat
                        berupa penggabungan, akuisisi, atau penjualan asset)
                        yang melibatkan kami dan/atau afiliasi kami; dan
                      </li>
                      <li>
                        Untuk memungkinkan kami mematuhi semua kewajiban
                        berdasarkan peraturan perundang-undangan yang berlaku,
                        (namun tidak terbatas pada menganggapi permintaan,
                        penyelidikan, atau arahan peraturan) dan melakukan
                        pemeriksaan audit, uji tuntas dan penyelidikan.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  PEMBERIAN INFORMASI PRIBADI YANG KAMI KUMPULKAN
                </p>

                <ol type="a">
                  <li>
                    Kami dapat mengungkapkan atau membagikan Informasi Pribadi
                    anda dengan afiliasi dan pihak lain untuk tujuan sebagai
                    berikut ini serta untuk tujuan lain yang diizinkan oleh
                    peraturan perundang-undangan yang berlaku:
                  </li>
                  <ul>
                    <li>
                      Jika anda adalah pengguna, untuk memungkinkan penyedia
                      layanan, untuk melakukan atau memberikan layanan;
                    </li>
                    <li>
                      Jika anda adalah penyedia layanan, untuk memungkinkan
                      pengguna untuk meminta atau menerima layanan dari andal;
                    </li>
                    <li>
                      Jika disyaratkan atau diotorisasikan oleh peraturan
                      perundang-undangan yang berlaku (termasuk namun tidak
                      terbatas pada menanggapi pertanyaan terkait regulasi,
                      penyelidikan atau pedoman, atau mematuhi persyaratan atau
                      ketentuan pengarsipan dan pelaporan berdasarkan
                      undang-undanga), untuk tujuan yang ditentukan dalam
                      peraturan perundang-undangan yang berlaku;
                    </li>
                    <li>
                      Jika terdapat proses hukum dalam bentuk apapun antara anda
                      dengan kami, atau antara anda dengan pihak lain,
                      sehubungan dengan, atau terkait dengan layanan, untuk
                      keperluan proses hokum tersebut;
                    </li>
                    <li>
                      Sehubungan dengan segala proses verifikasi yang menurut
                      kami atau penyedia layanan perlu sebelum kami mendaftarkan
                      anda sebagai pengguna.
                    </li>
                    <li>
                      Dalam keadaan darurat terkait kesehatan dan/atau
                      keselamatan anda (baik anda adalah pengguna atau penyedia
                      layanan) untuk keperluan menangani keadaan darurat
                      tersebut;
                    </li>
                    <li>
                      Sehubungan dengan, penggabungan, penjualan asset
                      perusahaan, konsolidasi atau restrukturasi, pembiayaan
                      atau akuisisi semua atau sebagian dari bisnis kami oleh
                      atau ke perusahaan lain, untuk keperluan transaksi
                      tersebut (bahkan jika kemudian transaksi tidak
                      dilanjutkan);
                    </li>
                    <li>
                      Sehubungan dengan proses klaim asuransi, kami akan
                      membagikan Informasi Pribadi anda untuk tujuan pemrosesan
                      klaim asuransi kepada perusahaan asuransi yang bekerja
                      sama atau berkolaborasi dengan kami;
                    </li>
                    <li>
                      Kepada pihak ketiga ( termasuk agen, vendor, pemasok,
                      kontraktor, mitra, dan pihak lain yang memberikan layanan
                      kepada kami atau anda, melakukan tugas atas nama kami,
                      atau pihak dengan siapa kami mengadakan kerja sama
                      komersial), untuk atau sehubungan dengan tujuan di mana
                      pihak ketiga tersebut terlibat atau tujuan kerja sama kami
                      dengan pihak ketiga tersebut (tergantung keadaannya), yang
                      dapat mencakupp diperbolehkannya pihak ketiga tersebut
                      untuk memperkenalkan atau menawarkan produk atau layanan
                      kepada anda, melakukan autentikasi terhadap anda atau
                      menghubungkan anda dengan Akun atau melakukan kegiatan
                      lain termasuk pemasaran, penelitian, analisisi dan
                      pengembangan produk; dan
                    </li>
                    <li>
                      Dalam hal kami berbagi Informasi Pribadi dengan afiliasi,
                      kami akan melakukannya dengan maksud agar mereka membantu
                      kami dalam menyediakan Aplikasi, untuk mengoperasikan
                      bisnis kami (termasuk, ketika anda berlangganan milis
                      kami, untuk tujuan pemasaran langsung), atau untuk tujuan
                      pengolahan data atas nama kami. Misalnya, sebuah afliasi
                      Asosiasi Pilot Garuda di negara lain dapat mengolah dan/atau menyimpan
                      Informasi Pribadi anda atas nama perushaan group Asosiasi Pilot Garuda
                      di negara anda. Semua afliasi kami berkomitmen untuk
                      mengolah Informasi Pribadi yang mereka terima dari kami
                      sesuai dengan Kebijakan Privasi dan peraturan
                      perundang-undangan yang berlaku ini.
                    </li>
                  </ul>

                  <li>
                    Ketika Informasi Pribadi tidak perlu dikaitkan dengan anda,
                    kami akan melakukan upaya yang wajar untuk menghapus
                    dikaitkannya Informasi Pribadi tersebut dengan anda sebagai
                    individu sebelum mengungkapkan atau berbagi informasi
                    tersebut.
                  </li>
                  <li>
                    Kami tidak akan menjual atau menyewakan Informasi Pribadi
                    anda.
                  </li>
                  <li>
                    Selain sebagaimana diatur dalam Kebijakan Privasi ini, kami
                    dapat mengungkapkan dan membagi Informasi Pribadi and ajika
                    kami memberitahu anda dan kami telah memperoleh persetujuan
                    anda untuk pengungkapan atau pembagian tersebut.
                  </li>
                </ol>
              </li>
              <li>
                <p>PENYIMPANAN INFORMASI PRIBADI</p>

                <ol type="a">
                  <li>
                    Informasi Pribadi anda hanya akan disimpan selama diperlukan
                    untuk memenuhi tujuan dari pengumpulannya, atau selama
                    penyimpanan tersebut diperlukan atau dipebolehkan oleh
                    peraturan perundang-undangan yang berlaku. Kami akan
                    berhenti menyimpan Informasi Pribadi, atau menghapus maksud
                    dari dikaitkannya Informasi Pribadi tersebut dengan anda
                    sebagai individu, segera setelah dianggap bahwa tujuan
                    pengumpulan Informasi Pribadi tersebut tidak lagi dibutuhkan
                    dengan menyimpan Informasi Pribadi dan penyimpnan tidak lagi
                    diperlukan untuk tujuan bisnis atau secara hokum.
                  </li>
                  <li>
                    Mohon diperhatikan bahwa masih ada kemungkinan bahwa
                    beberapa Informasi Pribadi anda disimpan oleh pihak lain
                    (termasuk oleh penyedia layanan, jika anda adalah pengguna;
                    atau oleh pengguna, jika anda adalah penyedia layanan)
                    dengan cara tertentu. Informasi yang disampaikan melalui
                    komunikasi antara pengguna dan penyedia layanan yang
                    dilakukan selain melalui penggunaan Aplikasi (seperti
                    melalui panggilan telepon, SMS, pesan seluler atau cara
                    komunikasi lainnya) juga dapat disimpan dengan beberapa
                    cara. Kami tidak mengizinkan dan mendorong penyimpanan
                    Informasi Pribadi dengan cara demikian dan kami tidak
                    bertanggung jawab kepada anda untuk hal tersebut. Kami tidak
                    akan bertanggung jawab atas penyimpanan Informasi Pribadi
                    anda. Anda setuju untuk mengganti rugi, membela, dan
                    membebaskan kami, pejabat, direktur, karyawan, agen mitra,
                    pemasok, kontraktor, dan afiliasi kami dari dan terhadap
                    setiap dan segala klaim, kerugian, kewajiban, biaya,
                    kerusakan, dan ongkos (termasuk tetapi tidak terbatas pada
                    biaya hokum dan pengeluaran baiaya ganti rugi penuh) yang
                    dihasilkan secara langsung atatu tidak langsung dari setiap
                    penyimpanan yang tidak sah atas Informasi Pribadi anda.
                  </li>
                </ol>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  AKSES DAN KOREKSI INFORMASI PRIBADI
                </p>

                <ol type="a">
                  <li>
                    Tunduk pada peraturan perundang-undangan yang berlaku, anda
                    dapat meminta kepada kami untuk mengakses dan/atau
                    mengoreksi Informasi Pribadi anda yang berada dalam
                    kepemilikan dan penguasaan kami, dengan menghubungi kami di
                    perincian yang disediakan dibawah ini.
                  </li>
                  <li>
                    Kami berhak menolak permintaan anda untuk mengakses, atau
                    untuk memperbaiki sebagian atau Informasi Pribadi anda yang
                    kami miliki atau kuasai jika diizinkan atau diperlukan
                    berdasarkan perundang-undangan yang berlaku. Hal ini
                    termasuk dalam keadaan di mana Informasi Pribadi tersebut
                    dapat berisi refrensi kepada orang lain atau di mana
                    permintaan untuk akses atau permintaan untuk mengoreksi
                    adalah untuk alas an yang kami anggap tidak relevan, tidak
                    serius, atau menyulitkan.
                  </li>
                </ol>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  TEMPAT KAMI MENYIMPAN INFORMASI PRIBADI ANDA
                </p>

                <ol type="a">
                  <li>
                    Informasi Pribadi dari anda yang kami kumpulkan dapat
                    disimpan, ditransfer, atau diolah oleh penyedia layanan
                    pihak ketiga. Kami akan menggunakan semua upaya yang wajar
                    untuk memastikan bahwa semua penyedia layanan pihak ketiga
                    tersebut memberikan tingkat perlindungan yang sebanding
                    dengan komitmen kami berdasarkan Kebijakan Privasi ini.
                  </li>
                  <li>
                    Informasi Pribadi anda juga dapat disimpan atau diproses di
                    luar negara anda oleh pihak yang bekerja untuk kami di
                    negara lain, atau oleh penyedia layanan pihak ketiga,
                    vendor, pemasok, mitra, kontrkator, atau afiliasi kami.
                    Dalam hal tersebut, kami akan memastikan bahwa Informasi
                    Pribadi tersebut tetap menjadi sbukej dari tingkat
                    perlindungan yang sebanding dengan apa yang disyaratkan
                    dalam hokum negara anda (dan, dalam hal apapun, sejalan
                    dengan komitmen kami dalam Kebijakan Privasi ini)
                  </li>
                </ol>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  KEAMANAN INFORMASI PRIBADI ANDA
                </p>
                <p>
                  Kerahasian Informasi Pribadi anda adalah hal yang terpenting
                  bagi kami. Kami akan memberlakukan upaya terbaik untuk
                  melindungi dan mengamankan data dan Informasi Pribadi anda
                  dari akses, pengumpulan, penggunaan atau pengungkapan oleh
                  orang-orang yang tidak berwenang dan dari pengeolahan yang
                  bertentangan dengan huukum, kehilangan yang tidak disengaja,
                  pemusnahan dan kerusakan atau risiko serupa. Namun, pengiriman
                  informasi melalui internet tidak sepenuhnya aman. Walau kami
                  akan berusaha sebaik mungkin untuk melindungi Informasi
                  Pribadi anda, anda mengakui bahwa kami tidak dapat menjamin
                  keutuhan dan keakuratan Informasi Pribadi apap pun yang anda
                  kirimkan melalui internet, atau menjamin bahwa Informasi
                  Pribadi tersebut tidak akan dicegat, diakses, diungkapkan,
                  diubah atau dihancurkan oleh pihak ketiga yang tidak
                  berwenang, karena faktor-faktor di luar kenadi kami. Anda
                  bertanggung jawab untuk menjaga kerahasiaan detail Akun anda,
                  termasuk kata sandi anda dan One Time Password (OTP) dengan
                  siapapun dan harus selalu menjaga dan bertanggung jawab atas
                  keaman perangkat yang anda gunakan.
                </p>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  PERUBAHAN ATAS KEBIJAKAN PRIVASI INI
                </p>
                <p>
                  Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas
                  kebijakan kami sendiri dari waktu ke waktu, memastikan bahwa
                  Kebijakan Privasi ini konsisten dengan perkembangan kami di
                  masa depan, dan/atau perubahan persyaratan hokum atau
                  peraturan. Jika kami memutuskan untuk mengubah Kebijakan
                  Privasi ini, kami akan memberitahu anda tentang perubahan
                  tersebut melalui pemberitahuan umum yang dipublikasikan pada
                  Aplikasi dan/atau situs web, atau sebaliknya ke alamat e-mail
                  anda yang tercantum dalam Akun anda. Anda setuju bahwa anda
                  bertanggung jawab untuk meninjau Kebijakan Privasi ini secara
                  teratur untuk informasi terbaru tentang pengolahan data dan
                  praktik perlindungan data kami, dan bahwa dengan anda terus
                  menggunakan Aplikasi atau situs web kami, berkomunikasi dengan
                  kami, atau mengakses dan menggunakan layanan setelah adanya
                  perubahan apapun terhadap Kebijakan Privasi ini akan dianggap
                  sebagai persetujuan anda terhadap Kebijakan Privasi ini dan
                  segala perubahannya.
                </p>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  PENGAKUAN DAN PERSETUJUAN
                </p>

                <ol type="a">
                  <li>
                    Dengan menyetujui Kebijakan Privasi, anda mengakui bahwa
                    anda telah membaca dan memahami Kebijakan Privasi ini dan
                    menyetujui segala ketentuannya. Secara khusus, anda setuju
                    dan memberikan persetujuan kepada kami untuk mengumpulkan,
                    menggunakan, membagikan, mengungkapkan, menyimpan,
                    mentransfer, atau mengolah Informasi Pribadi anda sesuai
                    dengan Kebijakan Privasi ini.
                  </li>
                  <li>
                    Dalam keadaan di mana anda memberikan kepada kami Informasi
                    Pribadi yang berkaitan dengan individu lain (seperti
                    Informasi Pribadi yang berkaitan dengan pasangan anda,
                    anggota keluarga, teman, atau pihak lain), anda menyatakan
                    dan menjamin bahwa anda telah memperoleh persetujuan dari
                    individu tersebut untuk, dan dengan ini menyetujui atas nama
                    individu tersebut untuk, pengumpulan, penggunaan,
                    pengungkapan dan pengolahan Informasi Pribadi mereka oleh
                    kami.
                  </li>
                  <li>
                    Anda dapat menarik persetujuan anda untuk setiap atau segala
                    pengumpulan, penggunaan atau pengungkapan Informasi Pribadi
                    anda kapan saja dengan memberikan kepada kami pemberitahuan
                    yang wajar secara tertulis menggunakan rincian kontak yang
                    disebutkan di bawah ini. Anda juga dapat menarik persetujuan
                    pengiriman komunikasi dan informasi tertentu dari kami
                    melalui fasilitas "opt-out" atau pilihan "berhenti
                    berlangganan" yang tersedia dalam pesan kami kepada anda.
                    Tergantung pada keadaan dan sifat persetujuan yang anda
                    tarik, anda harus memahami dan mengakui bahwa setelah
                    penarikan persetujuan tersebut, anda mungkin tidak lagi
                    dapat menggunakan Aplikasi atau layanan. Penarikan
                    persetujuan anda dapat mengakibatkan penghentian Akun anda
                    atau hubungan kontraktual anda dengan kami, dengan semua hak
                    dan kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah
                    menerima pemberitahuan untuk menarik persetujuan untuk
                    pengumpulan, penggunaan atau pengungkapan Informasi Pribadi
                    anda, kami akan menginformasikan anda tentang konsekuensi
                    yang mungkin terjadi dari penarikan tersebut sehingga anda
                    dapat memutuskan apakah anda tetap ingin menarik
                    persetujuan.
                  </li>
                </ol>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  MATERI PEMASARAN DAN PROMOSI
                </p>
                <p>
                  Kami dan mitra kami dapat mengirimkan anda pemasaran langsung,
                  iklan, dan komunikasi promosi melalui aplikasi
                  push-notification, pesan melalui Aplikasi, pos, panggilan
                  telepon, layanan pesan singkat (SMS), dan email (“Materi
                  Pemasaran”) jika anda telah setuju untuk berlangganan milis
                  kami, dan/atau setuju untuk menerima materi pemasaran dan
                  promosi dari kami. Anda dapat memilih untuk tidak menerima
                  komunikasi pemasaran tersebut kapan saja dengan mengklik
                  pilihan “berhenti berlangganan” yang ada dalam pesan yang
                  bersangkutan, atau menghubungi kami melalui detail kontak yang
                  tercantum di bawah ini. Mohon perhatikan bahwa jika anda
                  memilih untuk keluar, kami masih dapat mengirimi anda
                  pesan-pesan non-promosi, seperti tanda terima atau informasi
                  tentang Akun anda.
                </p>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">DATA ANONIM</p>
                <p>
                  Kami dapat membuat, menggunakan, melisensikan atau
                  mengungkapkan Informasi Pribadi, yang tersedia, dengan
                  catatan, (i) bahwa semua hal yang dapat mengidentifikasi telah
                  dihapus sehingga data, baik sendiri atau dikombinasi dengan
                  data lain yang tersedia, tidak dapat dihubungkan dengan atau
                  dikaitkan dengan atau tidak dapat mengidentifikasi suatu
                  individu, dan (ii) data serupa telah digabungkan sehingga data
                  asli membentuk bagian dari kumpulan data yang lebih besar.
                </p>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  PLATFORM PIHAK KETIGA
                </p>

                <ol type="a">
                  <li>
                    Aplikasi, situs web, dan Materi Pemasaran dapat berisi
                    tautan ke situs web yang dioperasikan oleh pihak ketiga.
                    Kami tidak mengendalikan atau menerima pertanggungjawaban
                    atau tanggung jawab untuk situs web ini dan untuk
                    pengumpulan, penggunaan, pemeliharaan, berbagi, atau
                    pengungkapan data dan informasi oleh pihak ketiga tersebut.
                    Silakan baca syarat dan ketentuan dan kebijakan privasi dari
                    situs web pihak ketiga tersebut untuk mengetahui bagaimana
                    mereka mengumpulkan dan menggunakan Informasi Pribadi anda.
                  </li>
                  <li>
                    Iklan yang terdapat pada Aplikasi,situs web, dan Materi
                    Pemasaran kami berfungsi sebagai tautan ke situs web
                    pengiklan dan dengan demikian segala informasi yang mereka
                    kumpulkan berdasarkan klik anda pada tautan itu akan
                    dikumpulkan dan digunakan oleh pengiklan yang relevan sesuai
                    dengan kebijakan privasi pengiklan tersebut.
                  </li>
                </ol>
              </li>
              <li>
                <p class="font-weight-bold font-catamaran">
                  CARA UNTUK MENGHUBUNGI KAMI
                </p>
                <p>
                  Jika anda memiliki pertanyaan mengenai Kebijakan Privasi ini
                  atau anda ingin mendapatkan akses dan/atau melakukan koreksi
                  terhadap Informasi Pribadi anda, silahkan hubungi
                  secretariat@apg.or.id
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../../config/api";

export default {
  name: "legal-agreement",

  data() {
    return {
      info: null,
      loading: true,
      errored: false,
    };
  },
  mounted() {
    axios
      .get(`${urlAPI}/badminton`)
      .then((response) => {
        this.info = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
