<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-20">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">List Report</div>
            <div class="card-body">
              <div class="table-responsive mt-2">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>ID APG</th>
                      <th>Nama</th>
                      <th>File</th>
                      <th>Date</th>
                      <th>AKSI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data,index) in datas" :key="data.id">
                      <td>{{ data.id_apg }}</td>
                      <td>{{ data.nama }}</td>
                      <td>
                        <a v-bind:href="data.file">{{ data.file }} </a>
                      </td>
                      <td>{{ data.created_at }}</td>
                      <td class="text-center" id="action">
                        <router-link
                          :to="{
                            name: 'report-detail',
                            params: { id: data.id },
                          }"
                          class="btn btn-sm btn-primary mr-2"
                          >Detail</router-link
                        >
                        <a
                          href="javascript:;0"  
                          v-on:click="deleteReport(data.id, index)"
                          class="btn btn-sm btn-danger"
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Index';
import { urlAPI } from '../../config/api';


export default {
  name: 'ReportList',
  components: {
    Sidebar,
  },

  data() {
    return {
      loggedIn: localStorage.getItem('loggedIn'),
      //state token
      token: localStorage.getItem('token'),
      //state user logged In
      datas: [],
    };
  },

  methods: {
    deleteReport(id, index) {
      if (confirm('Are You Sure Want To Delete This Report?'))
        axios
          .delete(`${urlAPI}/client/report/${id}`, {
            headers: { Authorization: 'Bearer ' + this.token },
          })
          .then((response) => {
            this.datas.splice(index, 1);
            console.log(response);
          })
          .catch((error) => {
            console.log(error.response);
          });
    },
  },

  created() {
    axios
      .get(`${urlAPI}/client/report`, {
        headers: { Authorization: 'Bearer ' + this.token },
      })
      .then((response) => {
        this.datas = response.data.data;
      });
  },
};
</script>
