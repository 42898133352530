<template>
  <div class="posts">
    <div class="container mt-5 mb-5">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Report</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="form-group">
                  <label>ID APG
                    <!-- <strong style="color: red">*</strong> -->
                  </label>
                  <input
                    type="text"
                    v-model="id_apg"
                    placeholder="ID APG"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Your Name
                    <!-- <strong style="color: red">*</strong> -->
                  </label>
                  <input
                    type="text"
                    v-model="nama"
                    placeholder="Your Name"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label
                    >Your criticisms & complaints
                    <!-- <strong style="color: red">*</strong> -->
                    </label>
                  <vue-editor id="keluhan" v-model="keluhan"></vue-editor>
                </div>
                <div class="form-group">
                  <label>File
                    <!-- <strong style="color: red">*</strong> -->
                    </label
                  ><br />
                  <input
                    class="form-control"
                    type="file"
                    @change="onFileUpload"
                  />
                  <p style="color: red">
                    If you have many files, please compress to .zip or .rar format.
                  </p>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary btn-md">Upload Report</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';
import { urlAPI } from '../../config/api';
import Alert from 'sweetalert2';

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      FILE: null,
      nama: '',
      keluhan: '',
      id_apg: '',
      error: null,
    };
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    onSubmit() {
      // upload file
      const formData = new FormData();
      formData.append('file', this.FILE);
      formData.append('nama', this.nama);
      formData.append('id_apg', this.id_apg);
      formData.append('keluhan', this.keluhan);
      axios
        .post(`${urlAPI}/report-webview`, formData)
        .then((response) => {
          this.$router.push({
            name: 'report-success',
          });
          console.log(response.data.data);
        })
        .catch((error) => {
          this.error = true;
          if (this.error == true) {
            Alert.fire({
              icon: 'error',
              title: 'Oops',
              text: 'wrong ID, try again!'
            })
          }
          console.log(error);
        });
    },
  },
};
</script>
