<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">EDIT Update Notif</div>
            <div class="card-body">
              <form @submit.prevent="organizationUpdate">
                <div class="form-group">
                  <label>Versi</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.versi"
                    placeholder="Masukkan Penomoran Versi Update"
                  />
                  <div v-if="validation.versi">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.versi[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Notifikasi</label>
                  <select class="form-control" v-model="data.notif">
                    <option>yes</option>
                    <option>no</option>
                  </select>
                  <div v-if="validation.notif">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.notif[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md btn-success mr-2">
                    UPDATE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index";
import { urlAPI } from "../../config/api"

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      data: {},
      validation: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    axios
      .get(
        `${urlAPI}/client/updates/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },
  methods: {
    organizationUpdate() {
      const formData = new FormData();
      formData.append("versi", this.data.versi);
      formData.append("notif", this.data.notif);
      axios
        .post(
          `${urlAPI}/client/updates/update/${this.$route.params.id}`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "update-notif",
          });
          console.log(response);
        })
        .catch((error) => {
          this.validation = error.response.data.data;
        });
    },
  },
};
</script>
