<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-20">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Description Sport - Sport ( Setelah Klik Button <strong>Hapus</strong>, mohon <strong>Refresh</strong> Browser )</div>
            <div class="card-body">
              <hr />

              <div class="table-responsive mt-2">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Nama Sport</th>
                      <th>AKSI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="descSport in descSports" :key="descSport.id">
                      <td>{{ descSport.nama_sport }}</td>
                      <td class="text-center">
                        <router-link
                          :to="{ name: 'desc-sport-edit', params: { id: descSport.id } }"
                          class="btn btn-sm btn-primary mr-2"
                          >EDIT</router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { urlAPI } from '../../../config/api';

export default {
  name: "DescSport",
  components: {
    Sidebar,
  },

  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user logged In
      descSports: [],
    };
  },
  created() {
    axios
      .get(`${urlAPI}/client/descsport`, {
        headers: { Authorization: "Bearer " + this.token },
      })
      .then((response) => {
        this.descSports = response.data.data;
      });
  },
};
</script>