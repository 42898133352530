<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Tambah Update Notifikasi</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="form-group">
                    <label>Versi</label>
                  <input
                    type="text"
                    v-model="versi"
                    placeholder="Masukkan Penomoran Versi Terbaru"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                    <label>Notifikasi</label>
                  <select class="form-control" v-model="notif">
                    <option>yes</option>
                    <option>no</option>
                  </select>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary btn-sm">
                    Create Notifications
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index";
import { urlAPI } from "../../config/api"

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      versi: "",
      notif: '',
      token: localStorage.getItem("token"),
    };
  },
  methods: {
    onSubmit() {
      // upload file
      const formData = new FormData();
      formData.append("versi", this.versi);
      formData.append("notif", this.notif);
      axios
        .post(
          `${urlAPI}/client/updates/create`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "update-notif",
          });
          console.log(response.data.data);
        });
    },
  },
};
</script>
