<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Report Berhasil Ditambahkan.</div>
            <div class="card-body">
              Report Berhasil Ditambahkan, kami akan segera memproses laporan anda.
              <div>
                  <router-link
                :class="['btn btn-sm btn-success mb-2 mt-3']"
                to="/report"
                >Masukkan Kembali Report.</router-link
              >
              <hr />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebviewReportSuccess',
  props: {
    msg: String
  }
}
</script>