<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Tambah Description Sport</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="form-group">
                  <label>Nama Sport</label>
                  <input
                    type="text"
                    v-model="nama_sport"
                    placeholder="Masukkan Nama Sport"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Deskripsi Singkat</label>
                  <vue-editor id="editor1" v-model="desc_singkat"></vue-editor>
                </div>
                <div class="form-group">
                  <label>Deskripsi Full</label>
                  <vue-editor id="editor2" v-model="desc_full"></vue-editor>
                </div>
                <div class="form-group">
                  <label>Member</label>
                  <vue-editor id="editor3" v-model="member"></vue-editor>
                </div>
                <div class="form-group">
                  <label>Event</label>
                  <vue-editor id="editor1" v-model="event"></vue-editor>
                </div>
                <div class="form-group">
                <label>Logo</label> <br/>
                  <input type="file" @change="onFileUpload" />
                </div>
                <div class="form-group">
                  <button class="btn btn-primary btn-sm">
                    Tambah Description Sport
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { VueEditor } from "vue2-editor";
import { urlAPI } from '../../../config/api';

export default {
  components: {
    Sidebar,
    VueEditor,
  },
  data() {
    return {
      FILE: null,
      nama_sport: "",
      desc_singkat: "",
      desc_full: "",
      member: "",
      event: "",
      token: localStorage.getItem("token"),
    };
  },
  methods: {
       onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    onSubmit() {
      // upload file
      const formData = new FormData();
      formData.append("file", this.FILE, this.FILE.nama_sport);
      formData.append("nama_sport", this.nama_sport);
      formData.append("desc_singkat", this.desc_singkat);
      formData.append("desc_full", this.desc_full);
      formData.append("member", this.member);
      formData.append("event", this.event);
      axios
        .post(
          `${urlAPI}/client/descsport/create`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "desc-sport",
          });
          console.log(response.data.data);
        });
    },
  },
};
</script>
