<template>
  <div class="col-lg-4">
    <div class="card">
      <div class="card-body">
        MAIN MENU
        <hr />
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Halaman Home
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <ul class="list-group">
                  <router-link
                    :to="{ name: 'banner' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Banner</router-link
                  >
                  <router-link
                    :to="{ name: 'pengumuman' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Pengumuman</router-link
                  >
                  <router-link
                    :to="{ name: 'ucapan' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Ucapan</router-link
                  >
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Halaman Division
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                <ul class="list-group">
                  <router-link
                    :to="{ name: 'organization-chart' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Organization Chart</router-link
                  >
                  <router-link
                    :to="{ name: 'technical' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Technical Division</router-link
                  >
                  <router-link
                    :to="{ name: 'industrial' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Industrial Division</router-link
                  >
                  <router-link
                    :to="{ name: 'treasury' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Treasury Division</router-link
                  >
                  <router-link
                    :to="{ name: 'affairs' }"
                    class="list-group-item text-dark text-decoration-none"
                    >Affairs Division</router-link
                  >
                  <router-link
                    :to="{ name: 'ifalpa' }"
                    class="list-group-item text-dark text-decoration-none"
                    >IFALPA Division</router-link
                  >
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Halaman Sport
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">
                <router-link
                  :to="{ name: 'badminton-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Badminton</router-link
                >
                <router-link
                  :to="{ name: 'basketball-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Basketball</router-link
                >
                <router-link
                  :to="{ name: 'cycling-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Cycling</router-link
                >
                <router-link
                  :to="{ name: 'football-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Football</router-link
                >
                <router-link
                  :to="{ name: 'golf-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Golf</router-link
                >
                <router-link
                  :to="{ name: 'motorcycle-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Motorcycle Community</router-link
                >
                <router-link
                  :to="{ name: 'shooting-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Shooting</router-link
                >
                <router-link
                  :to="{ name: 'tennis-dashboard' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Tennis</router-link
                >
                <router-link
                  :to="{ name: 'desc-sport' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Description Sport</router-link
                >
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSix">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Report
                </button>
              </h5>
            </div>
            <div
              id="collapseSix"
              class="collapse"
              aria-labelledby="headingSix"
              data-parent="#accordion"
            >
              <div class="card-body">
                <router-link
                  :to="{ name: 'report-list' }"
                  class="list-group-item text-dark text-decoration-none"
                  >List Report</router-link
                >
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingnine">
              <h5 class="mb-0">
                <router-link
                    :to="{ name: 'laporan-watermark' }"
                    class="btn btn-link"
                    data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="true"
                  aria-controls="collapseNine"
                    >Laporan Watermark</router-link
                  >
              </h5>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSeven">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Webview
                </button>
              </h5>
            </div>
            <div
              id="collapseSeven"
              class="collapse"
              aria-labelledby="headingSeven"
              data-parent="#accordion"
            >
              <div class="card-body">
                <router-link
                  :to="{ name: 'golf' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Golf</router-link
                >
                <router-link
                  :to="{ name: 'football' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Football</router-link
                >
                <router-link
                  :to="{ name: 'badminton' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Badminton</router-link
                >
                <router-link
                  :to="{ name: 'basketball' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Basketball</router-link
                >
                <router-link
                  :to="{ name: 'cycling' }"
                  class="list-group-item text-dark text-decoration-none"
                  >cycling</router-link
                >
                <router-link
                  :to="{ name: 'motorcycle' }"
                  class="list-group-item text-dark text-decoration-none"
                  >motorcycle</router-link
                >
                <router-link
                  :to="{ name: 'shooting' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Shooting</router-link
                >
                <router-link
                  :to="{ name: 'tennis' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Tennis</router-link
                >
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Settings
                </button>
              </h5>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordion"
            >
              <div class="card-body">
                <router-link
                  :to="{ name: 'user' }"
                  class="list-group-item text-dark text-decoration-none"
                  >List User Aplikasi APG</router-link
                >
                <router-link
                  :to="{ name: 'update-notif' }"
                  class="list-group-item text-dark text-decoration-none"
                  >Update Notif</router-link
                >
              </div>
            </div>
          </div>
          <ul class="list-group">
            <li
              @click="logout"
              class="list-group-item text-dark text-decoration-none"
              style="cursor: pointer"
            >
              LOGOUT
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../../config/api"

export default {
  name: "Sidebar",
  data() {
    return {
      //state loggedIn with localStorage
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user logged In
      user: [],
    };
  },
  methods: {
    logout() {
      const formData = new FormData();
      formData.append("api_token", this.token);
      axios
        .post(`${urlAPI}/logout`, formData)
        .then(() => {
          //remove localStorage
          localStorage.removeItem("loggedIn");
          //redirect
          return this.$router.push({ name: "home" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>