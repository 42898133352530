<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-20">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Ucapan - Home ( Setelah Klik Button <strong>Hapus</strong>, mohon <strong>Refresh</strong> Browser )</div>
            <div class="card-body">
              <router-link
                :class="['btn btn-md btn-success mb-2']"
                to="/ucapan-create"
                >Tambah Ucapan</router-link
              >
              <hr />

              <div class="table-responsive mt-2">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>TITLE</th>
                      <th>KONTEN</th>
                      <th>AKSI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ucapan in ucapans" :key="ucapan.id">
                      <td>{{ ucapan.nama }}</td>
                      <td>
                        <img
                          v-bind:src="ucapan.file"
                          class="img-fluid"
                          style="height: 50px, width: 50px"
                        />
                      </td>
                      <td class="text-center">
                        <router-link
                          :to="{ name: 'ucapan-edit', params: { id: ucapan.id } }"
                          class="btn btn-sm btn-primary mr-2"
                          >EDIT</router-link
                        >
                        <button
                          @click.prevent="ucapanDelete(ucapan.id)"
                          class="btn btn-sm btn-danger"
                        >
                          HAPUS
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { urlAPI } from '../../../config/api';

export default {
  name: "Ucapan",
  components: {
    Sidebar,
  },

  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user logged In
      ucapans: [],
    };
  },
  created() {
    axios
      .get(`${urlAPI}/client/ucapan`, {
        headers: { Authorization: "Bearer " + this.token },
      })
      .then((response) => {
        this.ucapans = response.data.data;
      });
  },
  methods: {
    ucapanDelete(id, index) {
      if(confirm('Are You Sure Want To Delete This File?'))
      axios
        .delete(
          `${urlAPI}/client/ucapan/delete/${id}`,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.ucapans.splice(index, 1);
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>