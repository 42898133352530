<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Edit User</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Nama Member APG</label>
                      <input
                        type="text"
                        v-model="data.name"
                        placeholder="Masukkan Nama Member APG"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Nomor Pegawai</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.no_peg"
                        placeholder="Masukkan Nomor Pegawai"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>id_apg</label>
                    <div class="form-group">
                      <input
                        type="number"
                        v-model="data.id_apg"
                        placeholder="Masukkan Nomor Pegawai"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        v-model="data.password"
                        placeholder="Masukkan Password untuk member tersebut"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Join APG</label>
                      <input type="date" class="form-control" v-model="data.join_apg" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Licence</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.license"
                        placeholder="Masukkan Nomor Pegawai"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Status</label>
                      <input
                        type="text"
                        v-model="data.status"
                        placeholder="Masukkan Status"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>rank</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.rank"
                        placeholder="Masukkan Rank"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Crew Type</label>
                      <input
                        type="text"
                        v-model="data.crew_type"
                        placeholder="Masukkan Crew Type"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Box</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.box"
                        placeholder="Masukkan Box"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tanggal Lahir</label>
                      <input type="date" class="form-control" v-model="data.date_of_birth" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Pendidikan Terakhir</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.pendidikan_terakhir"
                        placeholder="Masukkan Pendidikan Terakhir"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Email Garuda</label>
                      <input
                        type="email"
                        v-model="data.email_company"
                        placeholder="Masukkan Email Garuda"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Email Lainnya</label>
                    <div class="form-group">
                      <input
                        type="email"
                        v-model="data.email_other"
                        placeholder="Masukkan Email Lainnya"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        v-model="data.phone_number_1"
                        placeholder="Masukkan Phone Number"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Phone Number Lainnya</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.phone_number_2"
                        placeholder="Masukkan Phone Number Lainnya"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>FAX</label>
                      <input
                        type="text"
                        v-model="data.fax"
                        placeholder="Masukkan Fax"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>R</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.r"
                        placeholder="Masukkan r"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Jenis Kelamin</label>
                      <select class="form-control" v-model="data.sex">
                        <option>M</option>
                        <option>F</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Alamat Rumah</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.address_1"
                        placeholder="Masukkan Alamat Rumah"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Handphone 1</label>
                      <input
                        type="text"
                        v-model="data.handphone_1"
                        placeholder="Masukkan hadnphone"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Handphone 2</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.handphone_2"
                        placeholder="Masukkan Note"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tanggal Pensiun</label>
                      <input type="date" class="form-control" v-model="data.pension_date" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Information Pensiun 60th</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.information_pension_60th"
                        placeholder="Masukkan Informasi Pensiun 60th"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tahun Pensiun 60th</label>
                      <input
                        type="text"
                        v-model="data.year_pension_60th"
                        placeholder="Masukkan Tahun Pensiun 65th"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>PKWT</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.pkwt"
                        placeholder="Masukkan PKWT"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Pensiun 65th</label>
                      <input
                        type="text"
                        v-model="data.pension_65th"
                        placeholder="Masukkan Pensiun 65th"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Keluarga APG APS</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.keluarga_apg_aps"
                        placeholder="Masukkan Keluarga APG APS"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Training Qualified GA</label>
                      <input
                        type="text"
                        v-model="data.training_qualified_ga"
                        placeholder="Masukkan Traning Qualified GA"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Note</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="data.note"
                        placeholder="Masukkan Note"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                
                

                <div class="form-group">
                  <button class="btn btn-primary btn-sm">
                    Update User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index";
import { urlAPI } from '../../config/api';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      data: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    axios
      .get(
        `${urlAPI}/client/user/list-user/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },methods: {
    onSubmit() {
      const formData = new FormData();
      formData.append("name", this.data.name);
      formData.append("no_peg", this.data.no_peg);
      formData.append("id_apg", this.data.id_apg);
      formData.append("password", this.data.password);
      formData.append("join_apg", this.data.join_apg);
      formData.append("license", this.data.license);
      formData.append("status", this.data.status);
      formData.append("rank", this.data.rank);
      formData.append("crew_type", this.data.crew_type);
      formData.append("box", this.data.box);
      formData.append("date_of_birth", this.data.date_of_birth);
      formData.append("pendidikan_terakhir", this.data.pendidikan_terakhir);
      formData.append("email_company", this.data.email_company);
      formData.append("email_other", this.data.email_other);
      formData.append("phone_number_1", this.data.phone_number_1);
      formData.append("phone_number_2", this.data.phone_number_2);
      formData.append("fax", this.data.fax);
      formData.append("r", this.data.r);
      formData.append("sex", this.data.sex);
      formData.append("address_1", this.data.address_1);
      formData.append("pension_date", this.data.pension_date);
      formData.append("information_pension_60th", this.data.information_pension_60th);
      formData.append("year_pension_60th", this.data.year_pension_60th);
      formData.append("pkwt", this.data.pkwt);
      formData.append("pension_65th", this.data.pension_65th);
      formData.append("traning_qualified_ga", this.data.traning_qualified_ga);
      formData.append("note", this.data.note);
      formData.append("keluarga_apg_aps", this.data.keluarga_apg_aps);
      formData.append("handphone_1", this.data.handphone_1);
      formData.append("handphone_2", this.data.handphone_2);
      axios
        .post(
          `${urlAPI}/client/user/update-user/${this.$route.params.id}`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "user",
          });
          console.log(response);
        })
        .catch((error) => {
          this.validation = error.response.data.data;
        });
    },
  },
};
</script>
