<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">EDIT Desc Sport</div>
            <div class="card-body">
              <form @submit.prevent="organizationUpdate">
                <div class="form-group">
                  <label>Nama Sport</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="descSport.nama_sport"
                    placeholder="Masukkan Nama Organization"
                  />
                  <div v-if="validation.nama_sport">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.nama[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Deskripsi Singkat</label>
                  <vue-editor
                    id="editor1"
                    v-model="descSport.desc_singkat"
                  ></vue-editor>
                  <div v-if="validation.desc_singkat">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.desc_singkat[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Deskripsi Full</label>
                  <vue-editor
                    id="editor1"
                    v-model="descSport.desc_full"
                  ></vue-editor>
                  <div v-if="validation.desc_full">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.desc_full[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Member</label>
                  <vue-editor
                    id="editor1"
                    v-model="descSport.member"
                  ></vue-editor>
                  <div v-if="validation.member">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.member[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Event</label>
                  <vue-editor
                    id="editor1"
                    v-model="descSport.event"
                  ></vue-editor>
                  <div v-if="validation.event">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.event[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="file"
                    @change="onFileUpload"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md btn-success mr-2">
                    UPDATE
                  </button>
                  <router-link
                    :class="['btn btn-md btn-danger']"
                    to="/desc-sport"
                    >Cancel</router-link
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { VueEditor } from "vue2-editor";
import { urlAPI } from '../../../config/api';

export default {
  components: {
    Sidebar,
    VueEditor,
  },
  data() {
    return {
      descSport: {},
      validation: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    axios
      .get(
        `${urlAPI}/client/descsport/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
      .then((response) => {
        this.descSport = response.data.data;
      });
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    organizationUpdate() {
      const formData = new FormData();
      formData.append("file", this.FILE);
      formData.append("nama_sport", this.descSport.nama_sport);
      formData.append("desc_singkat", this.descSport.desc_singkat);
      formData.append("desc_full", this.descSport.desc_full);
      formData.append("member", this.descSport.member);
      formData.append("event", this.descSport.event);
      axios
        .post(
          `${urlAPI}/client/descsport/update/${this.$route.params.id}`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "desc-sport",
          });
          console.log(response);
        })
        .catch((error) => {
          this.validation = error.response.data.data;
        });
    },
  },
};
</script>
