<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">EDIT Ucapan</div>
            <div class="card-body">
              <form @submit.prevent="UcapanUpdate">
                <div class="form-group">
                  <label>Nama</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="ucapan.nama"
                    placeholder="Masukkan Nama Ucapan"
                  />
                  <div v-if="validation.nama">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.nama[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="file"
                    @change="onFileUpload"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md btn-success mr-2">
                    UPDATE
                  </button>
                  <button type="reset" class="btn btn-md btn-danger">
                    RESET
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { urlAPI } from '../../../config/api';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      ucapan: {},
      validation: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    axios
      .get(
        `${urlAPI}/client/ucapan/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
      .then((response) => {
        this.ucapan = response.data.data;
      });
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    UcapanUpdate() {
      const formData = new FormData();
      formData.append("file", this.FILE);
      formData.append("nama", this.ucapan.nama);
      axios
        .post(
          `${urlAPI}/client/ucapan/update/${this.$route.params.id}`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "ucapan",
          });
          console.log(response);
        })
        .catch((error) => {
          this.validation = error.response.data.data;
        });
    },
  },
};
</script>
