<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-20">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Update - Notifikasi</div>
            <div class="card-body">
              <div class="table-responsive mt-2">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Versi</th>
                      <th>Notifikasi</th>
                      <th>AKSI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="datas in data" :key="datas.id">
                      <td>{{ datas.versi }}</td>
                      <td>{{ datas.notif }}</td>
                      <td class="text-center">
                        <router-link
                          :to="{ name: 'update-notif-edit', params: { id: datas.id } }"
                          class="btn btn-sm btn-primary mr-2"
                          >EDIT</router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index";
import { urlAPI } from "../../config/api"

export default {
  name: "Update-notif",
  components: {
    Sidebar,
  },

  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user logged In
      data: [],
    };
  },
  created() {
    axios
      .get(`${urlAPI}/client/updates`, {
        headers: { Authorization: "Bearer " + this.token },
      })
      .then((response) => {
        this.data = response.data.data;
      });
  },
};
</script>