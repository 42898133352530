<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">TAMBAH User</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Nama Member APG</label>
                      <input
                        type="text"
                        v-model="name"
                        placeholder="Masukkan Nama Member APG"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Nomor Pegawai</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="no_peg"
                        placeholder="Masukkan Nomor Pegawai"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>id_apg</label>
                    <div class="form-group">
                      <input
                        type="number"
                        v-model="id_apg"
                        placeholder="Masukkan Nomor Pegawai"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        v-model="password"
                        placeholder="Masukkan Password untuk member tersebut"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Join APG</label>
                      <input type="date" class="form-control" v-model="join_apg" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Licence</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="license"
                        placeholder="Masukkan Nomor Pegawai"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Status</label>
                      <input
                        type="text"
                        v-model="status"
                        placeholder="Masukkan Status"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>rank</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="rank"
                        placeholder="Masukkan Rank"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Crew Type</label>
                      <input
                        type="text"
                        v-model="crew_type"
                        placeholder="Masukkan Crew Type"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Box</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="box"
                        placeholder="Masukkan Box"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tanggal Lahir</label>
                      <input type="date" class="form-control" v-model="date_of_birth" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Pendidikan Terakhir</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="pendidikan_terakhir"
                        placeholder="Masukkan Pendidikan Terakhir"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Email Garuda</label>
                      <input
                        type="email"
                        v-model="email_company"
                        placeholder="Masukkan Email Garuda"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Email Lainnya</label>
                    <div class="form-group">
                      <input
                        type="email"
                        v-model="email_other"
                        placeholder="Masukkan Email Lainnya"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        v-model="phone_number_1"
                        placeholder="Masukkan Phone Number"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Phone Number Lainnya</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="phone_number_2"
                        placeholder="Masukkan Phone Number Lainnya"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>FAX</label>
                      <input
                        type="text"
                        v-model="fax"
                        placeholder="Masukkan Fax"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>R</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="r"
                        placeholder="Masukkan r"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Jenis Kelamin</label>
                      <select class="form-control" v-model="sex">
                        <option>M</option>
                        <option>F</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Alamat Rumah</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="address_1"
                        placeholder="Masukkan Alamat Rumah"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Handphone !</label>
                      <input
                        type="text"
                        v-model="handphone_1"
                        placeholder="Masukkan hadnphone"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Handphone 2</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="handphone_2"
                        placeholder="Masukkan Note"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tanggal Pensiun</label>
                      <input type="date" class="form-control" v-model="pension_date" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Information Pensiun 60th</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="information_pension_60th"
                        placeholder="Masukkan Informasi Pensiun 60th"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tahun Pensiun 60th</label>
                      <input
                        type="text"
                        v-model="year_pension_60th"
                        placeholder="Masukkan Tahun Pensiun 65th"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>PKWT</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="pkwt"
                        placeholder="Masukkan PKWT"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Pensiun 65th</label>
                      <input
                        type="text"
                        v-model="pension_65th"
                        placeholder="Masukkan Pensiun 65th"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Keluarga APG APS</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="keluarga_apg_aps"
                        placeholder="Masukkan Keluarga APG APS"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Training Qualified GA</label>
                      <input
                        type="text"
                        v-model="training_qualified_ga"
                        placeholder="Masukkan Traning Qualified GA"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Note</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="note"
                        placeholder="Masukkan Note"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                
                

                <div class="form-group">
                  <button class="btn btn-primary btn-sm">
                    Tambahkan List User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index";
import { urlAPI } from '../../config/api';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      name: "",
      no_peg: "",
      id_apg: "",
      password: "",
      join_apg: "",
      license: "",
      status: "",
      rank: "",
      crew_type: "",
      box: "",
      date_of_birth: "",
      pendidikan_terakhir: "",
      email_company: "",
      email_other: "",
      phone_number_1: "",
      phone_number_2: "",
      fax: "",
      r: "",
      handphone_1: "",
      handphone_2: "",
      sex: "",
      address_1: "",
      pension_date: "",
      information_pension_60th: "",
      year_pension_60th: "",
      pkwt: "",
      pension_65th: "",
      traning_qualified_ga: "",
      keluarga_apg_aps: "",
      note: "",
      token: localStorage.getItem("token"),
    };
  },
  methods: {

    onSubmit() {
      // upload file
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("no_peg", this.no_peg);
      formData.append("id_apg", this.id_apg);
      formData.append("password", this.password);
      formData.append("join_apg", this.join_apg);
      formData.append("license", this.license);
      formData.append("status", this.status);
      formData.append("rank", this.rank);
      formData.append("crew_type", this.crew_type);
      formData.append("box", this.box);
      formData.append("date_of_birth", this.date_of_birth);
      formData.append("pendidikan_terakhir", this.pendidikan_terakhir);
      formData.append("email_company", this.email_company);
      formData.append("email_other", this.email_other);
      formData.append("phone_number_1", this.phone_number_1);
      formData.append("phone_number_2", this.phone_number_2);
      formData.append("fax", this.fax);
      formData.append("r", this.r);
      formData.append("sex", this.sex);
      formData.append("address_1", this.address_1);
      formData.append("pension_date", this.pension_date);
      formData.append("information_pension_60th", this.information_pension_60th);
      formData.append("year_pension_60th", this.year_pension_60th);
      formData.append("pkwt", this.pkwt);
      formData.append("pension_65th", this.pension_65th);
      formData.append("traning_qualified_ga", this.traning_qualified_ga);
      formData.append("note", this.note);
      formData.append("keluarga_apg_aps", this.keluarga_apg_aps);
      formData.append("handphone_1", this.handphone_1);
      formData.append("handphone_2", this.handphone_2);
      axios
        .post(
          `${urlAPI}/client/user/create`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "user",
          });
          console.log(response.data.data);
        });
    },
  },
};
</script>
