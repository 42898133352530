<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-20">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">Badminton Dashboard - Sport ( Setelah Klik Button <strong>Hapus</strong>, mohon <strong>Refresh</strong> Browser )</div>
            <div class="card-body">
              <router-link
                :class="['btn btn-md btn-success mb-2']"
                to="/badminton-dashboard-create"
                >Tambah File Badminton</router-link
              >
              <hr />
              <div class="table-responsive mt-2">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>Tipe File</th>
                      <th>File</th>
                      <th>Nama File</th>
                      <th>AKSI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in datas" :key="data.id">
                      <td>{{ data.tipe }}</td>
                      <td><img
                          v-bind:src="data.file"
                          class="img-fluid"
                          style="height: 50px, width: 50px"
                        /></td>
                      <td>{{ data.text }}</td>
                      <td class="text-center">
                        <router-link
                          :to="{ name: 'badminton-dashboard-edit', params: { id: data.id } }"
                          class="btn btn-sm btn-primary mr-2"
                          >EDIT</router-link
                        >
                        <button
                          @click.prevent="dataDelete(data.id)"
                          class="btn btn-sm btn-danger"
                        >
                          HAPUS
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Sidebar from "../../../components/Sidebar/Index";
import { urlAPI } from '../../../config/api';

export default {
  name: "Badminton",
  components: {
    Sidebar,
  },

  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user logged In
      datas: [],
    };
  },
  created() {
    axios
      .get(`${urlAPI}/client/badminton`, {
        headers: { Authorization: "Bearer " + this.token },
      })
      .then((response) => {
        this.datas = response.data.data;
      });
  },
  methods: {
    dataDelete(id, index) {
      if(confirm('Are You Sure Want To Delete This File?'))
      axios
        .delete(
          `${urlAPI}/client/badminton/delete/${id}`,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.datas.splice(index, 1);
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>