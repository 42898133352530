<template>
  <div class="posts" style="margin-top: 80px">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <Sidebar />
        <div class="col-lg-8">
          <div class="card">
            <div class="card-header">EDIT Laporan Ber-Watermark</div>
            <div class="card-body">
              <form @submit.prevent="dataUpdate">
                <div class="form-group">
                  <label>Nama Laporan</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.nama_laporan"
                    placeholder="Masukkan Nama Organization"
                  />
                  <div v-if="validation.nama_laporan">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.nama_laporan[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Division</label>
                  <select class="form-control" v-model="data.division">
                    <option>Ifalpa</option>
                    <option>Affairs</option>
                    <option>Industrial</option>
                    <option>Technical</option>
                    <option>Treasury</option>
                  </select>
                  <div v-if="validation.division">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.division[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="file"
                    @change="onFileUpload"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-md btn-success mr-2">
                    UPDATE
                  </button>
                  <router-link
                    :to="{ name: 'laporan-watermark' }"
                    class="btn btn-md btn-danger"
                    >BACK</router-link
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../../components/Sidebar/Index";
import { urlAPI } from "../../config/api"

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      data: {},
      validation: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    axios
      .get(
        `${urlAPI}/client/laporan/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    dataUpdate() {
      const formData = new FormData();
      formData.append("file", this.FILE);
      formData.append("nama_laporan", this.data.nama_laporan);
      formData.append("division", this.data.division);
      axios
        .post(
          `${urlAPI}/client/laporan/update/${this.$route.params.id}`,
          formData,
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then((response) => {
          this.$router.push({
            name: "laporan-watermark",
          });
          console.log(response);
        })
        .catch((error) => {
          this.validation = error.response.data.data;
        });
    },
  },
};
</script>
